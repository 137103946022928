import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import CTA from "../../../components/cta"
import ServiceAreas from "../../../components/serviceAreas"
import SubServicesAlt from "../../../components/subServicesAlt"
import Education from "../../../components/education"
import HeroSectionAlt from "../../../components/heroSectionAlt"
import Testimonials from "../../../components/testimonials"

function UnclogShowerDrain() {
  const data = useStaticQuery(graphql`
    query unclogShowerDrainImages {
      showerDrain: file(relativePath: { eq: "shower-drain-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Shower Drain Repair"
        description={`If your shower drain is clogged, we can fix it! With over 30 years of residential plumbing experience, so we'll unclog your shower drain quickly and easily`}
      />
      <HeroSectionAlt
        h1={`Shower Drain Repair`}
        h2={`Have a Master Plumber Fix Your Shower Drain`}
        h3={`Klein Plumbing Services has over 30 years of experience unclogging and repairing shower drains. `}
        p1={`A shower drain clog can lead to standing water while in use, and seemingly minor blockages can cause damage to your entire plumbing system if left unchecked. We've unclogged countless shower drains over three decades of serving the North Houston area, which is why our service is fast, professional, and affordable.`}
        heroImg={data.showerDrain.childImageSharp.fluid}
        heroImgAltTag={`Shower Drain Repair`}
      />
      <Education
        mainTitle={`Signs Your Shower Drain Might Be Clogged`}
        titleOne={`Slow Draining`}
        descriptionOne={`A healthy shower drains with no backed-up water; If your shower is draining slowly (or not at all), you have a clogged drain.`}
        titleTwo={`Foul Odors`}
        descriptionTwo={`Unpleasant odors coming from your drain could be an early sign of a clog - even if other signs are not yet obvious.`}
        titleThree={`Strange Sounds`}
        descriptionThree={`Gurgling noises coming from your drain could be a non-visual indicator that your shower is clogged.`}
      />
      <SubServicesAlt
        mainTitle={`Our Shower Drain Services`}
        titleOne={`Finding the Blockage`}
        descriptionOne={`Our master plumber can find the clog in your sink drain before it can cause any damage to your plumbing.`}
        titleTwo={`Breaking Up the Clog`}
        descriptionTwo={`After we have identified the root of the clog, we use industry-leading equipment and methods to break it up.`}
        titleThree={`After Service Checks`}
        descriptionThree={`After removing the blockage, our plumber will verify that your sink drains like it should and that everything is working properly.`}
      />
      {/* <Testimonials mainTitle={`Our Customers`} /> */}
      <ServiceAreas
        description={`shower drains`}
        subDescription={`shower drain repair`}
      />
      <CTA
        title={`Get a Free Quote to Fix Your Shower Drain Now`}
        buttonTitle={`Talk With a Master Plumber`}
      />
    </Layout>
  )
}

export default UnclogShowerDrain
